<template>
  <div style="padding: 10px; background-color: #fff">
    <van-nav-bar
      title="三方sdk"
      left-arrow
      @click-left="$goBack()"
      :fixed="true"
      placeholder
    >
    </van-nav-bar>
    <h3>Win生活第三方的SDK目录</h3>
    <br />
    <h3>一、第三方SDK列表</h3>
    <p>
      为保障Win生活相关功能的实现和应用的安全稳定运行，我们会接入由第三方提供的软件开发包（SDK）实现该目的。
    </p>
    <p>
      我们会对第三方SDK进行严格的安全检测，并要求合作伙伴采取严格的措施来保护您的个人数据。在满足新的服务需求及业务功能变更时，我们可能会调整我们接入的第三方SDK，并及时在本说明中向您公开说明接入第三方SDK的最新情况。请注意，第三方SDK可能因为版本升级、策略调整等原因导致数据类型存在一些变化，附其官方网站链接或相关隐私保护说明链接供参考，具体请以其公示的官方说明为准。
    </p>
    <br />
    <h3>安卓操作系统第三方SDK列表</h3>
    <p>产品/类型：高德地图定位</p>
    <p>
      使用目的：位置展示，用于GPS定位和IP定位，如搜索附近门店、参加城市特定活动
    </p>
    <p>使用场景：添加收货地址</p>
    <p>
      个人信息收集类型：Wifi信息、位置信息、经度、纬度、网络设备制造商、android_id
    </p>
    <p>收集方式：SDK本机采集，不涉及数据共享</p>
    <p>第三方公司名称：高德软件有限公司</p>
    <p>
      隐私保护说明：<a href="https://lbs.amap.com/pages/privacy"
        >https://lbs.amap.com/pages/privacy</a
      >
    </p>
    <br />
    <p>产品/类型：腾讯开放平台（QQ、QQ互联）</p>
    <p>使用目的：用于分享</p>
    <p>使用场景：页面分享至QQ好友或朋友圈时</p>
    <p>个人信息收集类型：个人常用设备信息、设备识别信息</p>
    <p>收集方式：SDK本机采集，不涉及数据共享</p>
    <p>第三方公司名称：深圳市腾讯计算机系统有限公司</p>
    <p>
      隐私保护说明：<a
        href="https://wiki.connect.qq.com/qq%e4%ba%92%e8%81%94sdk%e9%9a%90%e7%a7%81%e4%bf%9d%e6%8a%a4%e5%a3%b0%e6%98%8e"
        >https://wiki.connect.qq.com/qq%e4%ba%92%e8%81%94sdk%e9%9a%90%e7%a7%81%e4%bf%9d%e6%8a%a4%e5%a3%b0%e6%98%8e</a
      >
    </p>
    <br />
    <p>产品/类型：小米推送</p>
    <p>使用目的：用于消息推送</p>
    <p>使用场景：用户账号相关促销活动、消息提醒更新时</p>
    <p>个人信息收集类型：设备唯一标识码、网络状态、网络制式、设备序列号</p>
    <p>收集方式：SDK本机采集，不涉及数据共享</p>
    <p>第三方公司名称：小米科技有限责任公司</p>
    <p>
      隐私保护说明：<a href="https://dev.mi.com/console/doc/detail?pId=1822"
        >https://dev.mi.com/console/doc/detail?pId=1822</a
      >
    </p>
    <br />
    <p>产品/类型：华为HMS-Core（华为推送、华为应用升级）</p>
    <p>使用目的：用于消息推送</p>
    <p>使用场景：用户账号相关促销活动、消息提醒更新时</p>
    <p>个人信息收集类型：设备唯一标识码、设备序列号</p>
    <p>收集方式：SDK本机采集，不涉及数据共享</p>
    <p>第三方公司名称：华为技术有限公司</p>
    <p>
      隐私保护说明：<a
        href="https://developer.huawei.com/consumer/cn/devservice/term/"
        >https://developer.huawei.com/consumer/cn/devservice/term/</a
      >
    </p>
    <br />
    <p>产品/类型：魅族推送</p>
    <p>使用目的：用于消息推送</p>
    <p>使用场景：用户账号相关促销活动、消息提醒更新时</p>
    <p>
      个人信息收集类型：当前流量卡、匿名用户ID（OAID）、IMSI、位置信息、设备序列号
    </p>
    <p>收集方式：SDK本机采集，不涉及数据共享</p>
    <p>第三方公司名称：魅族科技有限公司</p>
    <p>
      隐私保护说明：<a href="https://www.meizu.com/legal.html"
        >https://www.meizu.com/legal.html</a
      >
    </p>
    <br />
    <p>产品/类型：Vivo</p>
    <p>使用目的：用于消息推送</p>
    <p>使用场景：用户账号相关促销活动、消息提醒更新时</p>
    <p>个人信息收集类型：匿名用户ID（OAID）</p>
    <p>收集方式：SDK本机采集，不涉及数据共享</p>
    <p>第三方公司名称：维沃移动通信有限公司</p>
    <p>
      隐私保护说明：<a href="https://dev.vivo.com.cn/documentCenter/doc/366"
        >https://dev.vivo.com.cn/documentCenter/doc/366</a
      >
    </p>
    <br />
    <p>产品/类型：ColorOS</p>
    <p>使用目的：用于消息推送</p>
    <p>使用场景：用户账号相关促销活动、消息提醒更新时</p>
    <p>个人信息收集类型：匿名用户ID（OAID）</p>
    <p>收集方式：SDK本机采集，不涉及数据共享</p>
    <p>第三方公司名称：OPPO广东移动通信有限公司</p>
    <p>
      隐私保护说明：<a href="https://security.oppo.com/cn/privacy.html"
        >https://security.oppo.com/cn/privacy.html</a
      >
    </p>
    <br />
    <p>产品/类型：华为广告</p>
    <p>使用目的：用于广告推荐</p>
    <p>使用场景：应用内商品或服务推荐</p>
    <p>个人信息收集类型：匿名用户ID（OAID）</p>
    <p>收集方式：SDK本机采集，不涉及数据共享</p>
    <p>第三方公司名称：华为技术有限公司</p>
    <p>
      隐私保护说明：<a
        href="https://developer.huawei.com/consumer/cn/devservice/term/"
        >https://developer.huawei.com/consumer/cn/devservice/term/</a
      >
    </p>
    <br />
    <p>产品/类型：网易云信</p>
    <p>使用目的：用于聊天沟通</p>
    <p>使用场景：用户与商家沟通，人工客服</p>
    <p>个人信息收集类型：用户ID</p>
    <p>收集方式：SDK本机采集，不涉及数据共享</p>
    <p>第三方公司名称：杭州网易质云科技有限公司</p>
    <p>
      隐私保护说明：<a href="https://netease.im/clauses?serviceType=3"
        >https://netease.im/clauses?serviceType=3</a
      >
    </p>
    <br />
    <p>产品/类型：阿里百川平台</p>
    <p>使用目的：搭建无线电商导购业务，满足开发用户消费需求</p>
    <p>使用场景：登录，电商业务搭建</p>
    <p>个人信息收集类型：信息结合识别特定自然人身份</p>
    <p>收集方式：SDK本机采集，不涉及数据共享</p>
    <p>第三方公司名称：浙江淘宝网络有限公司、淘宝（中国）软件有限公司</p>
    <p>
      隐私保护说明：<a
        href="https://terms.alicdn.com/legal-agreement/terms/suit_bu1_taobao/suit_bu1_taobao202107141244_11649.html?spm=a3c0d.7662652.1998907877.3.100abe4854swNu"
        >https://terms.alicdn.com/legal-agreement/terms/suit_bu1_taobao/suit_bu1_taobao202107141244_11649.html?spm=a3c0d.7662652.1998907877.3.100abe4854swNu</a
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "thirdSDK",
  props: {},
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style lang='less' scoped>
p {
  width: calc(100vw - 20px);
  word-break: break-all;
}
a{
  color: blue;
}
</style>